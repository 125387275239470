import PropTypes from 'prop-types'
import React from 'react'
import trans from '../../../trans'
import Button from '../../ui/ButtonComponent'
import { RouteManager } from '../../../containers/RouteManager/index'
import { RequestHeader } from '../../RequestPageCommon/RequestHeader'
import { TripRequestCostsDocuments } from '../TripRequestCostsDocuments'
import { TripRequestSummary } from '../TripRequestSummary'
import { Ability } from '../../RequestPageCommon/Ability/Ability'
import { TripRequestUnassignedDocuments } from '../TripRequestUnassignedDocuments'
import { TripRequestLumpSum } from '../TripRequestLumpSum'
import { TripPlanDocuments } from '../TripPlanDocuments/TripPlanDocuments'
import { TimelineElementsCompliance } from '../TimelineElementsCompliance'
import { RequestComments } from '../../RequestPageCommon/RequestComments'
import ReservationSettlementObserver from '../../RequestPageCommon/ReservationSettlementObserver'
import { get } from 'lodash'
import { withRouter } from 'react-router-dom'
import RequestCommentManager from '../../../containers/RequestCommentManager/RequestCommentManager'
import BasicRequestInfoRenderer from '../../RequestBasicInfoRenderer/RequestBasicInfoRenderer'

class TripRequestPageTripComponent extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isSetAsUnrealized: false,
    }
  }

  openPopup = () => {
    this.setState({ isSetAsUnrealized: true })
  }

  closePopup = () => {
    this.setState({ isSetAsUnrealized: false })
  }

  render() {
    const {
      request,
      instanceCurrency,
      sendToSettlement,
      cancelRequest,
      fetchRequest,
      unrealizeTrip,
      isModuleActive,
    } = this.props

    return (
      <RouteManager>
        {({ push, getRouteByName }) => (
          <div>
            <RequestHeader request={request} />

            <BasicRequestInfoRenderer />

            <TripPlanDocuments request={request} instanceCurrency={instanceCurrency} />

            <TripRequestCostsDocuments
              request={request}
              instanceCurrency={instanceCurrency}
              fetchRequest={fetchRequest}
            />

            <TripRequestUnassignedDocuments request={request} fetchRequest={fetchRequest} />

            <TripRequestLumpSum request={request} instanceCurrency={instanceCurrency} />

            <TripRequestSummary request={request} />

            <TimelineElementsCompliance />

            <div className='request-button-group'>
              <Ability ability={['cancel']}>
                <ReservationSettlementObserver
                  elements={get(request, 'combinedTravelElements', [])}
                >
                  <Button
                    onClick={(e) => {
                      e.preventDefault()
                      cancelRequest().then(() => {
                        this.props.history.goBack()
                      })
                    }}
                    link
                  >
                    {trans('ui.cancel-request')}
                  </Button>
                </ReservationSettlementObserver>
              </Ability>

              <Ability
                ability={['tripDidNotStarted']}
                fallback={() => (
                  <Ability ability={['sendToSettlement']}>
                    <Button
                      danger
                      pull_end
                      onClick={() => this.props.openPopup('setAsUnrealized', push)}
                    >
                      {trans('global.finish-trip-and-settle')}
                    </Button>
                  </Ability>
                )}
              >
                {!request.unrealized && (
                  <Ability ability={['setAsUnrealized']}>
                    <Button
                      danger
                      pull_end
                      onClick={() => this.props.openPopup('setAsUnrealized', push)}
                    >
                      {/*{trans('trip-request.trip-did-not-have-place')}*/}
                      {trans('global.finish-trip-and-settle')}
                    </Button>
                  </Ability>
                )}
              </Ability>
            </div>

            <RequestCommentManager request={request}>
              {({ comments }) => {
                return <RequestComments comments={comments} />
              }}
            </RequestCommentManager>
          </div>
        )}
      </RouteManager>
    )
  }
}

TripRequestPageTripComponent.propTypes = {
  request: PropTypes.object.isRequired,
  instanceCurrency: PropTypes.string.isRequired,
  sendToSettlement: PropTypes.func.isRequired,
  cancelRequest: PropTypes.func.isRequired,
  fetchRequest: PropTypes.func.isRequired,
  unrealizeTrip: PropTypes.func.isRequired,
}

export const TripRequestPageTrip = withRouter(TripRequestPageTripComponent)
export default { TripRequestPageTrip }
