import React from 'react'
import Icon from '../../ui/IconComponent'
import { Tooltip } from '../../Tooltip'
import trans from '../../../trans'

const Warning = (props) => {
  return (
    <Tooltip
      html={<span>{trans(props.rules[0].message.name, props.rules[0].message.params)}</span>}
    >
      <Icon className='is-gradient-warning plane-trip__offer-detail-price-warning' type='warning' />
    </Tooltip>
  )
}

export default Warning
export { Warning }
