import React, { useCallback } from 'react'
import AlertsController from '../../services/alerts-controller'
import trans from '../../trans'

export const UserProfileHover = ({
  hover,
}: {
  hover: Array<{
    name: string
    value: string
  }>
}) => {
  const copy = useCallback((item: { name: string; value: string }) => {
    AlertsController.displayAlert({
      type: 'info',
      message: trans('global.clipboard', { name: item.name }),
    })
    return navigator.clipboard.writeText(item.value)
  }, [])

  return (
    <>
      {hover.map((item) => {
        return (
          <div className='user-profile__hover-line' onClick={() => copy(item)}>
            <strong>{item.name}:</strong> {item.value}
          </div>
        )
      })}
    </>
  )
}
