import APIClient from '../../../services/APIClient'
import { STATUS_SETTLEMENT } from '../../../constants/request'
import { getCurrentAssistant } from '../login-as'

const getInitialState = () => {
  return {
    items: [],
    isLoading: false,
    isLoaded: false,
  }
}

// constants
export const RESET = 'dashboard-waiting-to-settlement::reset'
export const SET_ITEMS = 'dashboard-waiting-to-settlement::set-comments'
export const START_LOADING = 'dashboard-waiting-to-settlement::start-loading'
export const ADD_DOCUMENT_TO_REQUEST = 'dashboard-waiting-to-settlement::add-document-to-request'

export const MOUNT_POINT = 'dashboard-waiting-to-settlement'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET:
      return getInitialState()
    case SET_ITEMS:
      return {
        ...state,
        items: [...action.payload],
        isLoading: false,
        isLoaded: true,
      }
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      }
    case ADD_DOCUMENT_TO_REQUEST: {
      const { request, document } = action.payload

      return {
        ...state,
        items: state.items.map((_request) => {
          if (request['slug'] === _request['slug']) {
            _request['documents'].push(document)
          }
          return _request
        }),
      }
    }
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const setItems = (data) => (dispatch) => {
  dispatch({
    type: SET_ITEMS,
    payload: data,
  })
}

export const addDocumentToRequest = (request, document) => (dispatch) => {
  dispatch({
    type: ADD_DOCUMENT_TO_REQUEST,
    payload: {
      request,
      document,
    },
  })
}

export const fetch = () => (dispatch, getState) => {
  dispatch({ type: START_LOADING })

  const currentAssistant = getCurrentAssistant(getState())
  let user = null

  if (currentAssistant) {
    user = currentAssistant
  } else {
    user = getState().get('global').get('currentUser')
  }

  APIClient.getDashboardWaitingToSettlementRequests({
    statuses: STATUS_SETTLEMENT,
    users: user.slug,
  }).then((response) => {
    dispatch(setItems(response.data))
  })
}

export const uploadDocument = (request, files) => (dispatch) => {
  const promises = []

  for (let file of files) {
    const promise = new Promise((resolve) => {
      APIClient.uploadDocument(request['slug'], file)
        .then((response) => {
          dispatch(addDocumentToRequest(request, response.data))
          resolve()
        })
        .catch(() => {
          resolve()
        })
    })
    promises.push(promise)
  }

  return promises
}

// selectors
export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getItems = (state) => {
  return getState(state).items
}

export const getIsLoading = (state) => {
  return getState(state).isLoading
}

export const getIsLoaded = (state) => {
  return getState(state).isLoaded
}
