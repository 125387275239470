import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { get } from 'lodash'
import { trans } from '../../../trans'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import { Field, FieldArray } from 'redux-form/immutable'
import { FormField } from '../../ui/Form'
import { loadOptionsForGuSupervisorForUpdateUser } from '../../../store/app/users-for-select'
import { fetchMpkForAsyncSelect } from '../../../store/app/mpks'
import UserSelectOption from '../../../form/UserField/UserFieldOption'
import { components } from '../../../utils/react-select'
import { MpkSplitField } from './MpkSplitField'

class SensitiveData extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      clearMpkCache: false,
      mpk: props.data.mpk,
    }
  }

  componentDidMount() {
    const { fetchEditableMpk } = this.props

    fetchEditableMpk()
  }

  onCompanyChange = () => {
    const { changeFieldValue, form } = this.props

    changeFieldValue(form, 'mpk', [])

    this.setState({ clearMpkCache: !this.state.clearMpkCache })
  }

  onMpkChange = (mpk) => {
    const { changeFieldValue, form } = this.props

    changeFieldValue(form, 'mpk_id', get(mpk, 'id', null))
  }

  splitMpk() {
    this.setState({
      mpk: this.state.mpk.push({
        value: null,
        main: 0,
        percentage: 0,
      }),
    })
  }

  render() {
    const { handleSubmit, isSubmitting, isEditable, companies, grades, data } = this.props
    const { mpk } = this.state

    const { isLoading } = this.state

    return (
      <form onSubmit={handleSubmit}>
        {(isSubmitting || isLoading) && <Loader />}

        <h1 className='tabs__content-heading'>{trans('settings-page.sensitive-data')}</h1>

        <div className='sensitive-data__fields'>
          <Field
            disabled={!isEditable}
            name='company_id'
            type='select'
            label={trans('instance-users.company')}
            component={FormField}
            options={companies}
            onChange={(event, value) => this.onCompanyChange(value)}
            labeltop
          />

          <div className={'sensitive-data__mpk-wrapper'}>
            <div>
              <FieldArray
                name={'mpk'}
                disabled={!data.company_id}
                selectedOption={data.mpk}
                cacheUniqs={[this.state.clearMpkCache]}
                component={MpkSplitField}
                loadOptions={fetchMpkForAsyncSelect(data.company_id)}
                label={trans('instance-users.mpk')}
                placeholder={trans('instance-users.none')}
                noOptionsMessage={() => {
                  return trans('user.no-results-for-search-message')
                }}
                loadingMessage={() => {
                  return trans('user.searching-message')
                }}
                delay={500}
                returnFullItemValueOnChange={true}
              />
            </div>

            <Field name='mpk_id' type='hidden' labeltop component={FormField} />
          </div>

          <Field
            disabled={!isEditable}
            component={FormField}
            label={trans('instance-users.supervisor')}
            type='async-select'
            loadOptions={loadOptionsForGuSupervisorForUpdateUser}
            key={data.company_id}
            selectedOption={
              data.supervisor ? { id: data.supervisor.id, name: data.supervisor.full_name } : null
            }
            labeltop
            placeholder={trans('instance-users.none')}
            noOptionsMessage={() => {
              return trans('user.no-results-for-search-message')
            }}
            loadingMessage={() => {
              return trans('user.searching-message')
            }}
            name='supervisor'
            returnFullItemValueOnChange={true}
            components={{ ...components, Option: UserSelectOption }}
          />

          <Field
            disabled={!isEditable}
            name='grade_id'
            type='select'
            label={trans('instance-users.user-level')}
            component={FormField}
            options={grades}
            clearable={false}
            labeltop
          />

          <Field
            disabled={!isEditable}
            name='erp_id'
            type='text'
            label={trans('instance-users.erp-id')}
            component={FormField}
            labeltop
          />
          <Field
            disabled={!isEditable}
            name='hr_id'
            type='text'
            label={trans('instance-users.hr-id')}
            component={FormField}
            labeltop
          />
        </div>
      </form>
    )
  }
}

SensitiveData.propTypes = {
  changeFieldValue: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  data: PropTypes.object.isRequired,
  grades: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  mpks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
}

export default SensitiveData
export { SensitiveData }
