import APIClient from '../../../services/APIClient'
import { STATUS_TRIP } from '../../../constants/request'
import { getCurrentAssistant } from '../login-as'

const getInitialState = () => {
  return {
    counter: {
      currentTrips: 0,
      requestToAccept: 0,
      settlementToAccept: 0,
      waitingToSettlement: 0,
    },
    isLoading: false,
    isLoaded: false,
  }
}

// constants
export const RESET = 'dashboard-counter::reset'
export const SET_COUNTER = 'dashboard-counter::set-counter'
export const START_LOADING = 'dashboard-counter::start-loading'
export const MOUNT_POINT = 'dashboard-counter'

// reducer
export const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET:
      return getInitialState()
    case SET_COUNTER:
      return {
        ...state,
        counter: { ...action.payload },
        isLoading: false,
        isLoaded: true,
      }
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      }
    default:
      return state
  }
}

// actions
export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  })
}

export const setCounter = (data) => (dispatch) => {
  dispatch({
    type: SET_COUNTER,
    payload: data,
  })
}

export const fetch = () => (dispatch, getState) => {
  dispatch({ type: START_LOADING })

  const currentAssistant = getCurrentAssistant(getState())
  let user = currentAssistant ? currentAssistant : getState().get('global').get('currentUser')

  APIClient.getDashboardCounter(user.slug).then((response) => {
    dispatch(setCounter(response.data[0]))
  })
}

export const getState = (state) => {
  return state.get(MOUNT_POINT)
}

export const getCounter = (state) => {
  return getState(state).counter
}

export const getIsLoading = (state) => {
  return getState(state).isLoading
}

export const getIsLoaded = (state) => {
  return getState(state).isLoaded
}

export const isAnyItemInLoadedCounter = (state) => {
  return (
    getState(state).counter.currentTrips > 0 ||
    getState(state).counter.requestToAccept > 0 ||
    getState(state).counter.settlementToAccept > 0 ||
    getState(state).counter.waitingToSettlement > 0
  )
}
