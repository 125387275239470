import { Col, Radio } from '../../ui'
import { ExpenseRequestActions } from '../ExpenseRequestAccountingPage/ExpenseRequestActions'
import trans from '../../trans'
import Button from '../ui/ButtonComponent'
import { Row } from '../ui/AccordionTable'
import React, { useCallback, useState } from 'react'
import moment from 'moment/moment'
import APIClient from '../../services/APIClient'
import { useSelector } from 'react-redux'
import { getFilters } from '../../store/app/reports'


enum ReportAction {
  EXPORT_ALL = 'all',
  EXPORT_COST = 'cost',
  EXPORT_RESERVATION = 'reservation'
}

export default function ActionBar() {
  const [action, setAction] = useState<ReportAction>();
  const filters = useSelector(getFilters);

  const applyAction = useCallback(() => {
    const args = { ...filters }

    args.from = moment(args.from).toString()
    args.to = moment(args.to).toString()

    return APIClient.getReportsCockpit(args)
  }, [filters]);

  return (
    <Row className='notification-bar'>
      <Col sm={12} is_pull_end>
        <div className='notification-bar__row'>
          <ExpenseRequestActions hideNotApplicable>
            <div className='notification-bar__title'>
              <strong>{trans('reports.export-title')}</strong>
            </div>

            <div className='react-select__option'>
              <Radio
                name='status'
                label={trans('reports.export-default')}
                checked={action === ReportAction.EXPORT_ALL}
                onClick={() => {
                  setAction(ReportAction.EXPORT_ALL)
                }}
              />
            </div>

            <div className='react-select__option'>
              <Radio
                name='status'
                disabled={true}
                label={trans('reports.export-cost')}
                checked={action === ReportAction.EXPORT_COST}
                onClick={() => {
                  setAction(ReportAction.EXPORT_COST)
                }}
              />
            </div>

            <div className='react-select__option'>
              <Radio
                name='status'
                label={trans('reports.export-reservation')}
                checked={action === ReportAction.EXPORT_RESERVATION}
                onClick={() => {
                  setAction(ReportAction.EXPORT_RESERVATION)
                }}
              />
            </div>
          </ExpenseRequestActions>

          <Button
            primary
            xs
            disabled={!action}
            onClick={applyAction}
          >
            {trans('global.perform')}
          </Button>
        </div>
      </Col>
    </Row>
  )
}
