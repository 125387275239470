import React from 'react'
import { Field } from 'redux-form/immutable'
import { FormField, FormFieldRadioGroup } from '../../../ui/Form'
import { trans } from '../../../../trans'
import { TimelineElement, TimeLineElementContent, TimeLineElementHeader } from '../../../Timeline'
import { Col, Row } from '../../../../ui/Grid'
import Button from '../../../ui/ButtonComponent'
import Icon from '../../../ui/IconComponent'
import ExchangeSummaryContainer from '../../../../containers/ExchangeSummaryContainer'
import numeral from 'numeral'
import { Map } from 'immutable'
import { AmountFormatter } from '../../../AmountFormatter'
import { TravelPointLabel } from '../../../TravelPointLabel'
import { TypesMenu } from '../../index'
import { ifDeepDiff } from '../../../../utils/javascript'
import {
  TRAVEL_ACCOMMODATION_PROVIDED,
  TRAVEL_ACCOMODATION,
  TRAVEL_PRIVATE_ACCOMODATION,
} from '../../../../constants/travel'
import { Tooltip } from '../../../Tooltip'
import { keepInViewport } from '../KeepInViewport'
import { TravelPointDate } from '../../../TravelPointDate'
import { ForeignCurrenciesTooltip } from '../../../ForeignCurrenciesTooltip'

const KeepingViewportHeader = keepInViewport()(TimeLineElementHeader)

class PrivateAccommodation extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return ifDeepDiff(nextProps.accommodation, this.props.accommodation)
  }

  renderLabel() {
    const { accommodation } = this.props
    const arrivalDate = accommodation.getStartDate()
    const departureDate = accommodation.getEndDate()

    if (!accommodation.getStartLocation().city || !accommodation.getStartDate()) {
      return trans('request.fill-travel-data')
    } else {
      return (
        <div>
          <strong>{accommodation.weight}</strong>
          <TravelPointLabel location={accommodation.getStartLocation()} showDirections />
          <TravelPointDate start={arrivalDate} end={departureDate} />
        </div>
      )
    }
  }

  renderEdit() {
    const { accommodation, change } = this.props

    if (accommodation.isOpen) {
      return null
    }

    return (
      <span
        className='timeline-icon'
        onClick={() => {
          change('isOpen', true)
        }}
      >
        <Icon type='edit' lg gradient />
      </span>
    )
  }

  getExchangeSummaryPartials() {
    const { accommodation } = this.props

    const amount = accommodation.amount
    const amount_currency = accommodation.converted_amount_currency

    return Map({
      [amount_currency]: numeral(amount),
    })
  }

  isExchange() {
    const { accommodation } = this.props

    return accommodation.amount_currency && accommodation.amount_currency !== 'PLN'
  }

  renderCalculatedAmount() {
    const { accommodation } = this.props

    if (accommodation.virtual) {
      return trans('accommodation.check-out')
    }

    const amount = accommodation.converted_amount
    const currency = accommodation.converted_amount_currency

    return (
      <div className='flex-end-center'>
        {this.renderForeignCurrenciesTooltip()}{' '}
        <span>
          <AmountFormatter amount={amount} /> {currency}
        </span>
        <Field name='type' type='hidden' component={FormField} inputOnly labeltop />
      </div>
    )
  }

  renderForeignCurrenciesTooltip() {
    const { accommodation, instanceCurrency } = this.props

    return (
      <ForeignCurrenciesTooltip
        instanceCurrency={instanceCurrency}
        foreignCurrencies={accommodation.getAllAmounts()}
      />
    )
  }

  _getChangeTypeOptions() {
    const { accommodation } = this.props

    const options = {
      [TRAVEL_ACCOMODATION]: {
        value: TRAVEL_ACCOMODATION,
        label: trans('accommodation.accommodation'),
      },
      [TRAVEL_PRIVATE_ACCOMODATION]: {
        value: TRAVEL_PRIVATE_ACCOMODATION,
        label: trans('accommodation.private-accommodation'),
      },
      [TRAVEL_ACCOMMODATION_PROVIDED]: {
        value: TRAVEL_ACCOMMODATION_PROVIDED,
        label: trans('accommodation.provided'),
      },
    }

    if (accommodation.draft) {
      return Object.values(options)
    }

    return [options[accommodation.type]]
  }

  renderContent() {
    const {
      handleSubmit,
      element,
      onChangeType,
      change,
      minDate,
      maxDate,
      accommodation,
      form,
      maxStartDate,
    } = this.props

    return (
      <TimeLineElementContent isOpened={accommodation.isOpen}>
        <TypesMenu
          selected={accommodation.getType()}
          onChange={() => onChangeType(accommodation, null)}
          showChangeButton={accommodation.draft}
        />

        <div className='tooltip-content'>
          <form onSubmit={handleSubmit}>
            <Field name='draft' type='hidden' component={FormField} inputOnly labeltop />

            <Field name='id' type='hidden' component={FormField} inputOnly labeltop />

            <Field name='type' type='hidden' component={FormField} inputOnly labeltop />

            <Field name='round_trip' type='hidden' component={FormField} inputOnly labeltop />

            <ExchangeSummaryContainer
              key={`exchange-${form}`}
              partials={this.getExchangeSummaryPartials()}
              showSummary={false}
              labeltop
              request={this.props.request}
            />

            <Row>
              <Col xs={12}>
                <div className='accommodation__input-container accommodation__input-container--radios accommodation__input-container--radios-private'>
                  <Field
                    name='type'
                    component={FormFieldRadioGroup}
                    items={this._getChangeTypeOptions()}
                    label={trans('accommodation.accommodation-type')}
                    onChange={(f, value) => onChangeType(element, value)}
                    inline
                    labeltop
                  />
                </div>

                <Row>
                  <Col sm={6}>
                    <Field
                      name='location'
                      type='location'
                      component={FormField}
                      label={trans('accommodation.location')}
                      immutable
                      autoFocus
                      labeltop
                      noAutoTrigger
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Field
                      name='arrival_at'
                      type='datepicker'
                      component={FormField}
                      label={trans('accommodation.arrival-date')}
                      minDate={minDate}
                      maxDate={maxStartDate}
                      labeltop
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      name='departure_at'
                      type='datepicker'
                      component={FormField}
                      label={trans('accommodation.departure-date')}
                      minDate={accommodation.arrival_at}
                      maxDate={maxDate}
                      labeltop
                    />
                  </Col>
                </Row>

                {accommodation.type === TRAVEL_PRIVATE_ACCOMODATION && (
                  <Row className='is-margin-top-small'>
                    <Col sm={6}>
                      <Row>
                        <Col sm={6}>{trans('accommodation.lump-sum-label')}</Col>
                        <Col sm={6}>
                          <div className='is-pull-end'>
                            <span className='private-accomodation__cost'>
                              {this.isExchange() && (
                                <Tooltip
                                  html={
                                    <div>
                                      <AmountFormatter amount={accommodation.amount} />{' '}
                                      {accommodation.amount_currency}
                                    </div>
                                  }
                                >
                                  <Icon type='fx_2' className='icon--type-fx' />
                                </Tooltip>
                              )}

                              <AmountFormatter amount={accommodation.converted_amount} />
                              <span className='private-accomodation__cost-value'>PLN</span>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row className='is-margin-top'>
              <Col sm={12}>
                <Button primary pull_end xs type='submit'>
                  {trans('ui.save-trip-element')}
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </TimeLineElementContent>
    )
  }

  render() {
    const { element, onRemove, accommodation, request } = this.props

    return (
      <TimelineElement className={accommodation.className} type={accommodation.type} activeSimilar>
        <KeepingViewportHeader
          icon='accomodation_fill'
          gradient='true'
          isOpen={accommodation.isOpen}
        >
          <Col xs={9} className='timeline__header--title'>
            {this.renderLabel()}
          </Col>
          <Col style={{ paddingRight: '0' }} className='is-allign-end' xs={2}>
            {this.renderCalculatedAmount()}
          </Col>
          {request['abilities']['edit'] && (
            <Col xs={1} style={{ paddingLeft: '0' }} className='is-allign-end'>
              {this.renderEdit()}
              <span className='timeline-icon' onClick={() => onRemove(accommodation)}>
                <Icon type='trash' lg gradient />
              </span>
            </Col>
          )}
        </KeepingViewportHeader>
        {accommodation.isOpen && this.renderContent()}
      </TimelineElement>
    )
  }
}

export { PrivateAccommodation }
export default { PrivateAccommodation }
