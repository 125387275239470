import React, { Fragment, Component } from 'react'
import { connect } from '../../containers/LoginAs/connect'
import PropTypes from 'prop-types'
import trans from '../../trans'
import { Input } from '../../ui'
import {
  fetchLoggableUsers,
  getCurrentAssistant,
  LOGGABLE_AS_USERS_LIMIT,
} from '../../store/app/login-as'
import store from '../../store'
import { debounce } from 'lodash'
import { getState } from '../../store/app/login-as'

class SuperiorsList extends Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      userFindQuery: '',
    }

    this.userFindInputRef = React.createRef()
    this.debouncedInputChanged = debounce(this.onInputChange, 300)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isOpen === this.props.isOpen) {
      return
    }

    if (!this.props.isOpen) {
      this.clearUserFindQuery()
      return
    }

    if (this.userFindInputRef.current) {
      this.userFindInputRef.current.focus()
    }
  }

  renderList(users, label) {
    const {
      loginAs: {
        actions: { loginAs },
      },
    } = this.props

    const withoutCurrent = users.filter((user) => !user.current)

    if (!withoutCurrent || withoutCurrent.length === 0) return null

    return (
      <Fragment>
        <strong className='profile-dropdown__list-header'>{label}</strong>

        {withoutCurrent.map((user) => (
          <li
            key={user.slug + user.type}
            className='profile-dropdown__list-element'
            onClick={() => {
              return loginAs(user)
            }}
          >
            <div className='profile-dropdown__list-item'>
              <span className='profile-dropdown__list-item--name'>{user.full_name}</span>

              {user.company && (
                <span className='profile-dropdown__list-item--company'>{user.company.code}</span>
              )}
            </div>
          </li>
        ))}
      </Fragment>
    )
  }

  onInputChange = (phrase) => {
    this.search(phrase)
  }
  renderSearch = () => {
    if (!getCurrentAssistant(store.getState())) {
      return (
        <li className='profile-dropdown__list-search'>
          <Input
            inputRef={this.userFindInputRef}
            type='text'
            placeholder={trans('user.find-user')}
            value={this.state.userFindQuery}
            onChange={(e) => {
              this.setState({ userFindQuery: e.target.value })
              this.debouncedInputChanged(e.target.value)
            }}
          />
        </li>
      )
    }
  }

  clearUserFindQuery = () => {
    if (!getCurrentAssistant(store.getState())) {
      this.setState({ userFindQuery: '' })
      this.search('')
    }
  }

  search(phrase) {
    const offset =
      getState(store.getState()).filters.phrase === phrase
        ? getState(store.getState()).filters.offset + getState(store.getState()).filters.limit
        : 0

    // TODO: map store to props.
    store.dispatch(
      fetchLoggableUsers({
        phrase,
        offset,
        status: 'active',
        limit:
          getState(store.getState()) &&
          getState(store.getState()) &&
          getState(store.getState()).filters &&
          getState(store.getState()).filters.limit
            ? getState(store.getState()).filters.limit
            : LOGGABLE_AS_USERS_LIMIT,
      }),
    )
  }

  render() {
    const {
      loginAs: {
        selectors: { users },
        actions: { loginAs },
      },
      currentUser,
    } = this.props
    const isCurrentSuperior = users.find((user) => user.current)

    const phraseLength =
      getState(store.getState()) &&
      getState(store.getState()) &&
      getState(store.getState()).filters &&
      getState(store.getState()).filters.phrase
        ? getState(store.getState()).filters.phrase.length
        : 0

    if (!users.length && !phraseLength > 0) {
      return null
    }

    const filteredUsers = users

    const substitutions = filteredUsers.filter(({ type }) => type === 'deputy')
    const assistants = filteredUsers.filter(({ type }) => type === 'assistant')
    const instanceUsers = filteredUsers.filter(({ type }) => type === 'admin')

    return (
      <Fragment>
        <ul
          className='profile-dropdown__list'
          onScroll={(e) => {
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
            if (bottom) {
              this.debouncedInputChanged(this.state.userFindQuery)
            }
          }}
        >
          {this.renderSearch()}

          {isCurrentSuperior && (
            <Fragment>
              <strong className='profile-dropdown__list-header'>{trans('user.back-to')}</strong>
              <li
                className='profile-dropdown__list-element profile-dropdown__list-element--divider-bottom'
                onClick={() => loginAs(currentUser)}
              >
                {currentUser.full_name}
              </li>
            </Fragment>
          )}

          {this.renderList(substitutions, trans('user.substitutions'))}

          {this.renderList(assistants, trans('user.assistants'))}

          {this.renderList(instanceUsers, trans('user.users'))}
          {phraseLength > 0 && users.length === 0 ? (
            <Fragment>
              <strong className='profile-dropdown__list-header'></strong>
              <li className='profile-dropdown__list-element'>
                {trans('user.no-results-for-search-message')}
              </li>
            </Fragment>
          ) : (
            ''
          )}
        </ul>
      </Fragment>
    )
  }
}

SuperiorsList.propTypes = {
  currentUser: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default connect(SuperiorsList)
