import React, { Component } from 'react'
import { trans } from '../../../trans'
import Button from '../../ui/ButtonComponent'
import { Loader } from '../../ui/LoadingOverlay/Loader'
import Regular from '../../Formik/Fields/Regular'
import Datepicker from '../../Formik/Fields/Datepicker'
import SelectCountry from '../../Formik/Fields/SelectCountry'
import SelectLanguage from '../../Formik/Fields/SelectLanguage'
import Geolocation from '../../Formik/Fields/Geolocation'
import SelectSex from '../../Formik/Fields/SelectSex'

class PersonalData extends Component<any, any> {
  componentDidMount() {
    this.props.fetchMpk()
  }

  render() {
    const { handleSubmit, isSubmitting, countries, languages, isErp, currentUser } = this.props

    return (
      <form onSubmit={handleSubmit}>
        {isSubmitting && <Loader />}

        <h1 className='tabs__content-heading'>{trans('user.personal-data-header')}</h1>

        <div className='user-profile__fields-wrapper'>
          <div className='user-profile__fields'>
            <Regular
              type='text'
              label={trans('instance-users.email')}
              name='email'
              disabled={isErp || currentUser}
            />

            <Regular
              type='text'
              label={trans('user.first-name')}
              name='first_name'
              disabled={isErp}
            />

            <Regular
              type='text'
              label={trans('user.last-name')}
              name='last_name'
              disabled={isErp}
            />

            <Datepicker label={trans('user.birth-date')} name='birth_date' disabled={isErp} />

            <Regular type='text' label={trans('user.phone')} name='phone' disabled={isErp} />

            <SelectCountry
              label={trans('user.citizenship')}
              name='citizenship_id'
              countries={countries}
              disabled={isErp}
            />

            <SelectSex label={trans('user.sex')} name='sex' disabled={isErp} />

            <Regular type='text' label={trans('user.license_plate')} name='license_plate' />

            <Regular
              type='text'
              label={trans('user.company_license_plate')}
              name='company_license_plate'
            />

            <Regular type='text' label={trans('user.iban')} name='iban' />

            <Geolocation label={trans('user.work-location')} name='work_location' />

            <SelectLanguage label={trans('user.language')} name='lang' languages={languages} />
          </div>
        </div>

        <div className='is-margin-top'>
          <Button primary pull_end type='submit'>
            {trans('global.save')}
          </Button>
        </div>
      </form>
    )
  }
}

PersonalData.propTypes = {}

export default PersonalData
export { PersonalData }
